import { authService } from '@/modules/account/services/authService';
import { Component, Vue } from 'vue-property-decorator';
import { accountServices } from '../services/accountService';
import { validatePassword } from '@/utils/validate';

@Component({})
export default class ChangeCredential extends Vue {
  loading = false;
  errors = '';
  manage = {} as account.changePassword;

  showPassword = {
    old: false,
    new: false,
    confirm: false
  };

  get validationRoles() {
    return {
      oldPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('changeCredential.required.defaultPassword')}`));
            } else if (!validatePassword(value)) {
              callback(new Error(`${this.$t('changeCredential.required.passwordPattern')}`));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ],
      newPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('changeCredential.required.newPassword')}`));
            } else if (!validatePassword(value)) {
              callback(new Error(`${this.$t('changeCredential.required.passwordPattern')}`));
            } else {
              if (this.manage.confirmPassword !== '') {
                (this.$refs.changeCredential as any).validateField('confirmPassword');
              }
              callback();
            }
          },
          trigger: 'blur'
        }
      ],
      confirmPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('changeCredential.required.confirmPassword')}`));
            } else if (value !== this.manage.newPassword) {
              callback(new Error(`${this.$t('changeCredential.required.confirmPasswordMatch')}`));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ]
    }
  }

  public async change() {
    await (this.$refs.changeCredential as any).validate(async valid => {
      this.loading = true;
      if (valid) {
        accountServices.ChangePassword(this.manage).then(
          () => {
            this.$message({
              message: `<strong>${this.$t('changeCredential.success')}</strong><br>${this.$t('changeCredential.successRedirect')}`,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'success',
              duration: 5000
            });
            this.loading = false;
            setTimeout(() => {
              authService.Logout().then(() => {
                this.$router.push({ path: '/login' }).then(() => {
                  location.reload();
                });
              });
            }, 1500);
          },
          err => {
            if (err && err.exceptionMessage) {
              this.errors = err.exceptionMessage;
            } else if (err && err.response && err.response.exceptionMessage) {
              this.errors = err.response.exceptionMessage;
            } else if (err && err.response && err.response.data && err.response.data.length > 0) {
              this.errors = (err.response.data as string[]).reduce((a, b) => `${a}, ${b}`);
            }

            let _message = `${this.$t('changeCredential.error')}`;
            if (this.errors) {
              _message += `<br><i>${this.errors}</i>`;
            }

            this.$message({
              message: _message,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5000
            });
            this.loading = false;
          }
        );
      } else {
        this.loading = false;
      }
    });
  }

  public backToLogin() {
    this.$router.push({ path: '/login' });
  }
}
