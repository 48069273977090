import { anagraficaService } from '@/modules/anagrafica/services/anagraficaService';
import { validatePassword } from '@/utils/validate';
import { ElForm } from 'element-ui/types/form';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { accountServices } from '../services/accountService';

@Component({})
export default class Register extends Vue {
  @Prop()
  codiceFiscale: string

  loading = false;
  errors = '';
  giornalista: giornalisti.item = null;
  manage: account.register = null;

  showPassword = {
    password: false,
    confirm: false
  };

  async created() {
    if (this.codiceFiscale) {
      this.giornalista = await anagraficaService.DetailByCodiceFiscale(this.codiceFiscale);
      if (this.giornalista) {
        this.manage = {
          userName: this.giornalista.codiceFiscale,
          email: this.giornalista.mail || this.giornalista.pec,
          nome: this.giornalista.nome,
          cognome: this.giornalista.cognome,
          phoneNumber: this.giornalista.telefono,
          anagraficaEnteId: null,
          anagraficaGiornalistaId: this.giornalista.id,
          spid: false,
        } as account.register;
      }
    }
  }

  get email(){
    const xxx = this.manage?.email.split('@');
    let first = xxx[0].substring(0, 1);
    let last = xxx[0].substring(xxx[0].length -1, xxx[0].length);

    return `${first.padEnd(xxx[0].length -2, '*')}${last}@${xxx[1]}`;
  }

  get validationRoles() {
    return {
      userName: [
        {
          required: true,
          message: `${this.$t('register.required.userName')}`,
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => this.checkUsernameExist(rule, value, callback),
          trigger: 'blur'
        }
      ],
      email: [
        {
          required: true,
          message: `${this.$t('register.required.email')}`,
          trigger: 'blur'
        },
        {
          type: 'email',
          message: `${this.$t('register.required.emailPattern')}`,
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => this.checkEmailExist(rule, value, callback),
          trigger: 'blur'
        }
      ],
      nome: [{
        required: true,
        message: `${this.$t('register.required.nome')}`,
        trigger: 'blur'
      }],
      cognome: [{
        required: true,
        message: `${this.$t('register.required.cognome')}`,
        trigger: 'blur'
      }],
      password: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('register.required.password')}`));
            } else if (!validatePassword(value)) {
              callback(new Error(`${this.$t('register.required.passwordPattern')}`));
            } else {
              if (this.manage.confirmPassword !== '') {
                (this.$refs.registerForm as any).validateField('confirmPassword');
              }
              callback();
            }
          },
          trigger: 'blur'
        }
      ],
      confirmPassword: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error(`${this.$t('register.required.confirmPassword')}`));
            } else if (value !== this.manage.password) {
              callback(new Error(`${this.$t('register.required.confirmPasswordMatch')}`));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ]
    }
  }

  private checkUsernameExist(rule, value, callback) {
    if (!value) {
      callback(new Error(`${this.$t('recoverPassword.required.usernameRequired')}`));
    }
    accountServices.CheckUsernameExist(value).then((result: boolean) => {
      if (result) {
        callback(new Error(`${this.$t('register.required.userNameExsist')}`));
      } else {
        callback();
      }
    });
  }

  private checkEmailExist(rule, value, callback) {
    if (!value) {
      callback(new Error(`${this.$t('register.required.emailRequired')}`));
    } else {
      callback();

      // accountServices.CheckEmailExist(value).then((result: boolean) => {
      //   if (result) {
      //     callback(new Error(`${this.$t('register.required.emailExsist')}`));
      //   } else {
      //     callback();
      //   }
      // });
    }
  }

  public async register() {
    (this.$refs.registerForm as ElForm).validate(async valid => {
      this.loading = true;

      if (valid) {
        await accountServices
          .Register(this.manage)
          .then((result: user.item) => {
            let message = `${this.$t('register.success', { value: result.nomeCompleto })}`;
            if (!result.lockoutEnabled) {
              message += `<br/>${this.$t('register.successRedirect')}`;
            } else {
              message += `<br/>${this.$t('register.successRedirectWhenLockoutEnabled')}`;
            }

            this.$msgbox({
              title: `${this.$t('register.successRedirectTitle')}`,
              message,
              confirmButtonText: result.lockoutEnabled ? `${this.$t('register.successRedirectWhenLockoutEnabledBtn')}` : `${this.$t('register.successRedirectBtn')}`,
              dangerouslyUseHTMLString: true,
              type: 'success'
            }).then(() => {
              this.$router.push({ path: '/login' });
            });
            this.loading = false;
          })
          .catch(err => {
            if (err && err.exceptionMessage) {
              this.errors = err.exceptionMessage;
            } else if (err && err.response && err.response.exceptionMessage) {
              this.errors = err.response.exceptionMessage;
            } else if (err && err.response && err.response.data && err.response.data.length > 0) {
              this.errors = (err.response.data as string[]).reduce((a, b) => `${a}, ${b}`);
            }

            let _message = `${this.$t('register.error')}`;
            if (this.errors) {
              _message += `<br><i>${this.errors}</i>`;
            }

            this.$message({
              message: _message,
              showClose: true,
              dangerouslyUseHTMLString: true,
              type: 'error',
              duration: 5000
            });
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    });
  }

  public async backToLogin() {
    this.$router.push({ path: '/login' });
  }
}
